// Entry point for the build script in your package.json
import 'semantic-ui-css/semantic.js'

$(document).on('turbolinks:load', function() {
  $('.message .close')
    .on('click', function() {
      $(this)
        .closest('.message')
        .transition('fade')
      ;
    });

  $("#displayDatePickerOff").click(function(){
      $("#datepicker").hide();
  });

  $("#displayDatePickerOn").click(function(){
        $("#datepicker").show();
    });

  $('.ui.radio.checkbox')
    .checkbox()
  ;

  // dish forms validation (onsubmit)
  $('.new-dish').on('submit', (e) => {
    let errorsCount = 0;
    ['clearing_account', 'bank_account', 'expense_account', 'income_account', 'shipping_account', 'handling_account', 'tax_code', 'location_id', 'shop_id'].forEach(function(toppingField) {
      let selectedOption = $(`[name="dish[toppings[${toppingField}]]"] option:selected`);
      if (selectedOption.val() != undefined && !selectedOption.val()) {
        errorsCount++;
        $(`[name="dish[toppings[${toppingField}]]"]`).addClass('error');
        if ($(`[name="dish[toppings[${toppingField}]]"]`).next('.pointing.prompt').length == 0)
          $(`[name="dish[toppings[${toppingField}]]"]`).after($(`<div class="ui red pointing prompt label transition visible">Please select a ${formatToppingField(toppingField)}</div>`));
      } else {
        $(`[name="dish[toppings[${toppingField}]]"]`).removeClass('error');
        $(`[name="dish[toppings[${toppingField}]]"]`).next('.pointing.prompt').remove();
      }
    })
    
    if (errorsCount > 0) {
      e.preventDefault();
    }
  })
  // dish forms validation (onblur)
  $('.new-dish').on('focusout', () => {
    ['clearing_account', 'bank_account', 'expense_account', 'income_account', 'shipping_account', 'handling_account', 'tax_code', 'location_id', 'shop_id'].forEach(function(toppingField) {
      let selectedOption = $(`[name="dish[toppings[${toppingField}]]"] option:selected`);
      if (selectedOption.val() != undefined && !selectedOption.val()) {
        $(`[name="dish[toppings[${toppingField}]]"]`).addClass('error');
        if ($(`[name="dish[toppings[${toppingField}]]"]`).next('.pointing.prompt').length == 0)
          $(`[name="dish[toppings[${toppingField}]]"]`).after($(`<div class="ui red pointing prompt label transition visible">Please select a ${formatToppingField(toppingField)}</div>`));
      } else {
        $(`[name="dish[toppings[${toppingField}]]"]`).removeClass('error');
        $(`[name="dish[toppings[${toppingField}]]"]`).next('.pointing.prompt').remove();
      }
    })
  })
})

// Cancellation management - Do not move from here
global.cancelAutomation = function(dish_id) {
  document.cookie='disable_dish_id='+dish_id;
  $('#cancel-automation').modal('show');
};

// formatting select fields for error messages
global.formatToppingField = function(str) {
  return str.split('_').map(function(chaine) {
    const lower = chaine.toLowerCase();
    return chaine.charAt(0).toUpperCase() + lower.slice(1);
  }).join(' ')
}